$primary-color: #fa4b0c;
$secondary-color: #ffffff;
$medium: 900px;

.emoji-styles {
  // Override the crazy z-index (2147483647)
  z-index: 5 !important;
}

#feedMeBtn {
  background-color: $primary-color;

  z-index: 10;

  cursor: pointer;

  position: absolute;
  margin-left: -5.5em;
  bottom: 0;
  left: 50%;

  max-width: 100%;

  color: white;
  border: none;

  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;

  padding: 0.5em 3em 0.5em 3em;

  text-transform: uppercase;
  font-family: "cby", sans-serif;
  font-size: 24pt;

  -webkit-transition: ease-in 0.2s;
  -moz-transition: ease-in 0.2s;
  transition: ease-in 0.2s;

  .text {
    text-align: center;
    overflow: hidden;
    user-select: none;
    animation: fadeIn 1s;
    width: 5em;

    .loadingEmoji {
      animation: spin 0.75s infinite linear;
    }
  }
}

#feedMeBtn.cooldown {
  cursor: not-allowed;
  background-color: #727272;
}

#feedMeBtn.enabled:hover {
  color: $primary-color;
  box-shadow: inset 0 3em 0 0 #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@media screen and (max-width: $medium) {
  #feedMeBtn {
    bottom: 0.5em;
    border-radius: 0.5em;
  }
}
