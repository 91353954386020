$primary-color: #fa4a0a;
$intermed-color: #4b4b4b;
$intermed-light-color: gray;
$component-width: 23em;
$medium: 500px;

#hidden {
  transform: translateX(27em);
}

.filterPanel {
  z-index: 15;
  position: absolute;
  top: 0; // Use for right alignment
  right: 0;

  padding: 1.75em;
  padding-left: 2.5em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;

  margin-top: 30vh;

  animation: slide-in-from-right 0.5s forwards;

  width: $component-width;
  height: 22em;

  color: $intermed-color;
  background-color: white;

  font-size: 11pt;

  .closeTab {
    cursor: pointer;
    position: absolute;

    margin-top: 9em;
    margin-left: -4.5em;

    width: 3em;
    height: 4em;

    border-radius: 1em;

    background-color: $primary-color;

    #close {
      width: 2.5em;

      margin-left: auto;
      margin-right: auto;
      margin-top: 0.7em;
      display: block;
    }
  }

  #subheading {
    font-weight: 500;
    letter-spacing: 0.2em;
  }

  #status {
    color: $intermed-light-color;
    font-size: 11pt;
    float: right;
  }

  .slider {
    color: $primary-color;

    margin-top: 1em;
    width: 22em;

    .rc-slider-track {
      background-color: $primary-color;
    }

    .rc-slider-handle {
      // height: 2em;
      // width: 2em;

      // background-image: url("../icons/location.svg");
      // background-repeat: no-repeat, repeat;
      border: solid 2px #ff8153;
    }

    .rc-slider-handle:hover {
      border-color: $primary-color;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      box-shadow: none;
      border-color: $primary-color;
    }
  }

  .location {
    width: 100%;

    .searchBox {
      margin-top: 1em;

      input {
        width: 17.5em;
        padding: 0.7em;

        border: 2px solid $primary-color;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
      }

      input:focus {
        outline: none !important;

        border-top: 2.5px solid $intermed-color;
        border-bottom: 2.5px solid $intermed-color;
        border-left: 2.5px solid $intermed-color;
      }

      .locationButton {
        display: inline-flex;
        align-items: center;

        border-radius: 0.5em;

        cursor: pointer;
        width: 24em;

        color: white;

        height: 3.05em;

        border: 2px solid $primary-color;
        background-color: $primary-color;

        .locationIcon {
          margin-left: 4.5em;
          width: 2em;
        }

        #txt {
          margin-left: 0.5em;

          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 11pt;
        }
      }
    }
  }

  .priceSlider {
    margin-top: 1.5em;
  }

  .ratingSlider {
    margin-top: 1.5em;
  }

  .dividingLine {
    float: left;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    width: 22em;
    border: none;
    border-top: 1px solid lightgray;
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}

.tab {
  cursor: pointer;

  position: absolute;
  background-color: $primary-color;

  margin-top: 42vh;

  width: 4em;
  height: 5em;
  z-index: 10;

  position: absolute;
  top: 0px; // Use for right alignment
  right: 0px;

  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;

  #filter {
    width: 2.5em;

    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    display: block;
  }
}

@media screen and (max-width: $medium) {
  .filterPanel {
    margin-top: 20vh;

    width: 20em;

    font-size: 10pt;

    #subheading {
      font-weight: 500;
      letter-spacing: 0.1em;
    }

    #status {
      color: $intermed-light-color;
      font-size: 10pt;
      float: right;
    }

    .slider {
      width: 20em;

      .rc-slider-handle {
        margin-top: -0.6em;

        height: 1.5em;
        width: 1.5em;
      }
    }

    .location {
      width: 100%;

      .searchBox {
        margin-top: 1em;

        .locationButton {
          width: 20em;

          .locationIcon {
            margin-left: 2.3em;
            width: 2em;
          }
        }
      }
    }

    .dividingLine {
      width: 20em;
    }
  }

  .tab {
    margin-top: 35vh;
  }
}
