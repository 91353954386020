$primary-color: #fa4b0c;
$secondary-color: #ffffff;

.h1 {
  color: $primary-color;

  font-family: "cby", sans-serif;
  font-size: 36pt;
}

a {
  text-decoration: underline;
  color: black;
}

.privacy {
  padding: 3em;
}

.notFound {
  padding: 3em;

  #sad_cat_gif {
    margin-top: 5em;
    border-radius: 1em;
  }
}
